<template>
  <div class="content-box">
    <main class="content-main">
<!--      <a href="https://www.beeftochina.com.cn" target="_blank">
        <img style="width: 1248px;height: 450px" src="@/assets/image/cn/infobanner.png" alt="">
      </a> -->
      <el-carousel height="230px" :interval="3000">
        <el-carousel-item  v-for="(item, index) in list_img" :key="index">
          <h3 class="png">
            <a :href="item.Url" target="_blank">
              <img :src="getUrl(item.PhotoAddr)" style="height: 230px;"/>
            </a>
          </h3>
        </el-carousel-item>
      </el-carousel>
      <!-- <img style="width: 1248px;height: 450px" src="@/assets/image/cn/infobanner.png" alt=""> -->
      <!-- 必孚热点 -->
      <!--
      <section class="section-card-box">
        <section-title title="必孚热点"/>
        <div class="card-box">
          <section-card-headlines v-for="item in headlinesList" :key="item.NewsID" :data="item" :is-en-expire="isInfoEnExpire"/>
        </div>
      </section>
      -->
      <!-- 必孚资讯 -->
      <section class="section-card-box">
        <section-title title="新闻资讯" />
        <div class="shipinbutton">
          <div class="shipinbutton_button" @click="OnShipin">
            <img src="@/assets/image/cn/Button_shipin.png" width="120" />
          </div>
        </div>
        <div class="card-box">
          <section-card-headlines v-for="item in expressList" :key="item.NewsID" :data="item" :is-en-expire="true"/>
        </div>
        <div class="more hand" @click="handleShowMore(1, 'btcinfo')">更多</div>
      </section>
      <!-- 必孚数据分析 -->
      <section class="section-card-box">
        <section-title title="数据分析"/>
        <div class="card-box">
          <section-card v-for="item in analysysList" :key="item.NewsID" :data="item" :is-en-expire="isAnalysisEnExpire"/>
        </div>
        <div class="more hand" @click="handleShowMore(1, 'btcanalysis')">更多</div>
      </section>
      <!-- BTC Report -->
      <section class="section-card-box">
        <section-title title="必孚报告"/>
        <div class="report-box">
          <!--
          <div class="week-more hand" @click="handleTapPrev">
            <img src="@/assets/image/price/analysis/more.png" class="trans">
          </div>
          -->
          <!--<transition-group :name="transOrientation" class="card-item-box">-->
            <report-card v-for="item in reportList" :key="item.BulletinID" :data="item" :is-en-expire="isReportEnExpire" style="min-width: 353px; margin-left: 10px;"/>
          <!--</transition-group>-->
          <!--
          <div class="week-more hand" @click="handleTapNext">
            <img src="@/assets/image/price/analysis/more.png">
          </div>
          -->
        </div>
        <div class="more hand" @click="handleShowMore(2)">更多</div>
      </section>
    </main>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'
import ReportCard from './components/report-card'
import SectionCard from './components/section-card'
import SectionCardHeadlines from './components/section-card-headlines'
import SectionTitle from './components/section-title'

export default {
  mixins: [commonmix],
  name: 'CnBTCInfo',
  components: {
    SectionCard,
    SectionTitle,
    SectionCardHeadlines,
    ReportCard
  },
  data () {
    return {
      headlinesList: [],
      headlinesQuery: {
        NewsLanguageCode: 'zh-CN'
      },
      listQuery: {
        PageNum: 1,
        PageSize: 4,
        NewsLanguageCode: 'zh-CN'
      },
      expressList: [],
      analysysList: [],
      reportTotal: 0,
      reportList: [],
      reportQuery: {
        PageNum: 1,
        PageSize: 9,
        Lang: 0
      },
      transOrientation: '',
      list_img: []
    }
  },
  computed: {
    totalPage () {
      return Math.ceil(this.reportTotal / this.reportQuery.PageSize)
    },
    isInfoEnExpire () {
      return this.getUserProfile(this.$t('message._beefpicked')) && this.getUserProfile(this.$t('message._beefnewsandevents')) && this.getUserProfile(this.$t('message._newsanalysis'))
    },
    isAnalysisEnExpire () {
      console.log('isAnalysisEnExpire')
      console.log(this.getUserProfile(this.$t('message._beefmarketanalysis')))
      console.log(this.getUserProfile(this.$t('message._btcdataanalysis')))
      console.log('1111')
      return !this.getUserProfile(this.$t('message._btcdataanalysis'))
    },
    isReportEnExpire () {
      return this.getUserProfile(this.$t('message._btcreport'))
    }
  },
  mounted () {
    this.getAdvsList()
    this.getHeadLine()
    this.getNewsList('btcinfo', 'expressList')
    this.getNewsList('btcanalysis', 'analysysList')
    this.getReport()
  },
  methods: {
    OnShipin () {
      this.$router.push('/cn/BTCEyes')
    },
    getUrl (addr) {
      if (addr) {
        return `${process.env.VUE_APP_STATIC}${addr}`
      }
    },
    getAdvsList () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebHomePageBanner', { Type: 2, UseRange: 'WebPage' }).then((res) => {
        this.$store.dispatch('SetLoadingStatus', false)
        this.list_img = res
      }).catch((err) => {
        this.$store.dispatch('SetLoadingStatus', false)
        console.log(err)
      })
    },
    getHeadLine () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetBtcInfoHeadlines', this.headlinesQuery)
        .then(() => {
          this.headlinesList = this.$store.getters.newsRows
          this.$store.dispatch('SetLoadingStatus', false)
        }).catch((err) => {
          this.$store.dispatch('SetLoadingStatus', false)
          console.log(err)
        })
    },
    getNewsList (type, list) {
      this.listQuery.Type = type
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetBtcInfoNewsBySection', this.listQuery)
        .then(() => {
          this.$store.dispatch('SetLoadingStatus', false)
          this[list] = this.$store.getters.newsRows
        }).catch((err) => {
          this.$store.dispatch('SetLoadingStatus', false)
          console.log(err)
        })
    },
    getReport () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetBeefInfoReport', this.reportQuery)
        .then(() => {
          this.reportList = this.$store.getters.newsRows
          this.reportTotal = this.$store.getters.newsTotal
          this.$store.dispatch('SetLoadingStatus', false)
        }).catch((err) => {
          this.$store.dispatch('SetLoadingStatus', false)
          console.log(err)
        })
    },
    handleClickNews (e) {
      if (this.$store.getters.token) {
        this.$store.commit('SET_NEWSID', e)
        // this.$router.push(`/BTCInfo/Article/${e}`)
        const path = `/cn/BTCInfo/ArticleFree/${e}`
        const routeData = this.$router.resolve({ path: path })
        const open = window.open(routeData.href, '_blank')
        if (open === null || typeof (open) === 'undefined') {
          this.$router.push(path)
        }
      } else {
        this.openCnLoginModal()
      }
      // sessionStorage.setItem('recommendation', e)
      // const path = `/BTCInfo/Article/${e}`
      // const routeData = this.$router.resolve({ path: path })
      // const open = window.open(routeData.href, '_blank')
      // if (open === null || typeof (open) === 'undefined') {
      //   this.$router.push(path)
      // }
    },
    handleShowMore (type, section) {
      if (type === 1) {
        this.$router.push({ path: '/cn/BTCInfo/Section', query: { type: section } })
      }
      if (type === 2) {
        this.$router.push('/cn/BTCInfo/Report')
      }
    },
    handleTapPrev () {
      this.transOrientation = 'left'
      if (this.reportQuery.PageNum > 1) {
        this.reportQuery.PageNum -= 1
      }
      this.getReport()
    },
    handleTapNext () {
      this.transOrientation = 'right'
      if (this.reportQuery.PageNum < this.totalPage) {
        this.reportQuery.PageNum += 1
      }
      this.getReport()
    }
  }
}
</script>

<style scoped>
.shipinbutton {
  position: absolute;
  padding-left: 1100px;
  padding-top:8px;
  width:1240px;
}

.shipinbutton_button {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 35px;
}

.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.content-main {
  width: 1248px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.content-main > .section-card-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-box {
  display: flex;
}

.more {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0 40px;
  font-size: 12px;
  display: inline-block;
  border: 1px solid #e0e0e0;
  margin: 10px 0;
  margin-top: 20px;
}

.report-box{
  display: inline-block;
  width: 1200px;
  justify-content: center;
  align-items: center;
}

.report-box > div{
  margin: 20px;
}

.week-more{
  width: 30px;
  height: 106px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 2px #e0e0e0;
}

.week-more > img{
  width: 6px;
  height: 13px;
}

.trans{
  transform:rotate(180deg);
}

.card-item-box {
  width: 1100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
}

.left-enter-active,
.left-leave-active {
  transition: all 0.2s ease;
}
.left-enter-from,
.left-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.right-enter-active,
.right-leave-active {
  transition: all 0.2s ease;
}
.right-enter-from,
.right-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 1;
  line-height: 230px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.png img {
  width: 100%;
  height: 100%;
}

.el-carousel__container {
  width: 1226px;
  height: 460px;
}

/deep/ .el-carousel__indicators {
    center: unset;
    transform: unset;
    right: 2%;
}
/deep/ .el-carousel__button {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #D6D6D6;
}
/deep/ .is-active .el-carousel__button {
    background: #ffffff;
}
</style>
