<template>
  <div class="line-box">
    <span>{{ title }}</span>
    <div/>
  </div>
</template>

<script>
export default {
  name: 'CnBTCInfoSectionTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.line-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.line-box > span {
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: bold;
}

.line-box > div {
  width: 40px;
  height: 2px;
  background: #87a5bd;
}
</style>
